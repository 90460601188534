import React from 'react';
import "../style.scss"

const TsInfo = ({vehicle}) => {
    return (
        <div className="ts-info">
            <h2 className="ts-info__number">{vehicle.vehicle_brand} {vehicle.vehicle_number.toUpperCase()}</h2>
            {/*<span className="ts-info__holder">Петров Алексей  +79876543210</span>*/}
        </div>
    );
};

export default TsInfo;