import React, {useCallback, useState} from 'react'
import './index.scss'
import { useDropzone } from 'react-dropzone'
import APIService, { createRequest } from '../../services/APIService';
import { CameraSVG, DocSVG } from '../svgImage'

const Dropzone = ({ files, setFiles, accept = '', type, children  }) => {
  const [filesOriginalNames, setFilesOriginalNames] = useState({})

  const onDrop = useCallback(
    (acceptedFiles) => {
      for (const attachment of acceptedFiles) {
        const data = new FormData()
        data.append('file', attachment)
        const fileOriginalName = attachment.name
        console.log('attachment', attachment)

        createRequest(APIService.REQUESTS.FILE_UPLOAD, {
          body: data,
        }).then(({ data }) =>{
          setFiles((prev) => [...prev, {name: data.filename, size: attachment.size,  url: data.url, comment: "",...(type === "doc" ? {type: "CONTRACT"} : {}) }])
          setFilesOriginalNames((prev) => ({...prev, [data.filename]: fileOriginalName}))
        })
      }
    },
    [setFiles, setFilesOriginalNames]
  )

  const removeFile = (index, serverFileName) => {
    setFiles((prev) => prev.filter((item, idx) => idx !== index))
    setFilesOriginalNames((prev) => {
      delete prev[serverFileName]
      return prev
    })
  }

  const validateFormatFile = useCallback((fileName) => {
    const fileArray = fileName.split('.')
    const images = ['png', 'jpeg', 'jpg', 'webp']
    return images.includes(fileArray[fileArray.length - 1].toLowerCase())
  }, [])



  const thumbs = files.map((file, idx) => {
    const isDefinedFormat = validateFormatFile(file.name)
    return (
        <div className={"thumb" + (!isDefinedFormat ? ' doc' : '')} key={file.name} title={filesOriginalNames[file.name]}>
          <div className="thumbInner">
            <div className={`thumb__${isDefinedFormat ? 'image' : 'document'}-container`}>
            {
              isDefinedFormat ? (
                  <img
                      src={file.url}
                      className="img"
                      alt={filesOriginalNames[file.name]}
                      title={filesOriginalNames[file.name]}
                  />
                  ) : (
                  <DocSVG
                      alt={filesOriginalNames[file.name]}
                      title={filesOriginalNames[file.name]}
                  />
                  )
            }
            <button type="button" className="dropzone-remove" onClick={() => removeFile(idx, file.name)}>
              ×
            </button>
            </div>
            <div className="thumb__info">
              <div className={"thumb__filename"}>{filesOriginalNames[file.name]}</div>
              <div className={"thumb__file-info"}>{file.name.split('.').pop()}, {Math.round(file.size / 1024)} Кб</div>
            </div>
          </div>
        </div>
    );
  })

  const { getRootProps, getInputProps } = useDropzone({ accept, onDrop })
  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {
          type === "photo" &&
          <p>
            <CameraSVG />
            Загрузите фото отходов для оценки примерного объема
          </p>
        }
          {
            type === "doc" &&
          <p>Загрузите документы</p>
        }
      </div>
      {children}
      {thumbs && !!thumbs?.length && (<aside className="thumbsContainer">{thumbs}</aside>)}
      {type === "doc" && (
          <div className="document-restricted-info">
            Максимальный размер одного файла – 5 Мб<br/>
            Допустимые форматы документа – doc, docx, xls, xlsx, pdf, tif, tiff, jpeg, bmp, png, gif, rtf, rar, zip<br/>
            Если требуется приложить более одного файла к одному документу, поместите их в архив
          </div>
      )}
    </>
  )
}

export default Dropzone
