import React from 'react'
import ReactDOM from 'react-dom'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import "react-datepicker/dist/react-datepicker.css"
import './assets/styles/style.scss'
import './assets/styles/media.scss'
import App from './App'
import {Provider} from "react-redux";
import {store} from "./redux/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
