import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from '../view/Home'
import Layout from "../components/Layout";
import Consumer from "../view/Consumer";
import Order from "../view/Order";
import NotFound from "../view/NotFound";
import Payment from "../view/Payment";
import PaymentStatus from "../view/Payment/PaymentStatus";
import Profile from "../view/Profile";
import PrivatRoute from "../components/PrivateRoute";

export const PAYMENT_SUCCESS  = "payment_success"
export const PAYMENT_FAIL  = "payment_fail"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return (
        <Router>
            <Layout>
                <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route exact path="/consumer">
                        <Consumer/>
                    </Route>
                    <Route exact path="/o/:id">
                        <Order/>
                    </Route>
                    <Route exact path="/payment">
                        <Payment/>
                    </Route>
                    <Route exact path={`/${PAYMENT_SUCCESS}`}>
                        <PaymentStatus/>
                    </Route>
                    <Route exact path={`/${PAYMENT_FAIL}`}>
                        <PaymentStatus/>
                    </Route>
                    <PrivatRoute exact path="/profile">
                        <Profile />
                    </PrivatRoute>
                    <Route path="*">
                        <NotFound/>
                    </Route>
                </Switch>
            </Layout>
        </Router>
    )

}