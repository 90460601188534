import * as Yup from "yup";

export const validationSchema = (applicant) => {
    return {
        "comment": Yup.string(),
        "waste_removal_day_period": Yup.string().required("Обязательное поле"),
        "waste_volume": Yup.string(),
        "first_name": Yup.string().required("Обязательное поле")
            .matches(/^[a-zA-zа-яА-Я]+$/i, "Имя может содержать только буквы"),
        "last_name": Yup.string().required("Обязательное поле")
            .matches(/^[a-zA-zа-яА-Я]+$/i, "Фамилия может содержать только буквы"),
        "phone": Yup.string().required("Обязательное поле"),
        "email": Yup.string()
          .nullable(true)
          .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, "Введите корректный email")
          .transform((_, value) => value ? value: null),
        "waste_geo_address": Yup.string().required("Обязательное поле"),
        "waste_geo_latitude": Yup.string().required("Обязательное поле"),
        "waste_geo_longitude": Yup.string().required("Обязательное поле"),
        "waste_removal_date": Yup.string().required("Обязательное поле"),
        "privacyConfirmed": Yup.boolean().oneOf([true], "Подтвердите соглашение"),
        "has_barrier": Yup.boolean(),
        "documents": Yup.number().when({
            is: () => applicant === 2 || applicant === 3,
            then: Yup.number().not([0], "Приложите документы"),
            otherwise: Yup.number()
        }),
        ...(applicant !== 1 ? {
            "company_name": Yup.string().required("Обязательное поле"),
            "inn": Yup.string().required("Обязательное поле").matches(/^[0-9]+$/, "ИНН может содержать только цифры").when({
                is: () => applicant === 2,
                then: Yup.string().length(12, 'Поле должно состоять из 12 символов'),
            }).when({
                is: () => applicant === 3,
                then: Yup.string().length(10, 'Поле должно состоять из 10 символов'),
            }),
            "company_address_reg": Yup.string().required("Обязательное поле")
        } : {})
    }
}