import React, {useState} from 'react';
import Modal from "../Modal";
import PhoneForm from "./PhoneForm";
import ConfirmCode from "./ConfirmCode";

const AuthModal = ({showModal, setShowModal}) => {
    const [step, setStep] = useState(0)
    return (
        <Modal isOpen={showModal}>
            <h2 className="auth__title">Авторизация</h2>
                {step === 0 ?
                    <PhoneForm setStep={setStep}/> :
                    <ConfirmCode setShowModal={setShowModal}/>
                }
            <button className="popup__close" onClick={() => {
                setShowModal(false)
                setStep(0)
            }}>
                ×
            </button>
        </Modal>
    );
};

export default AuthModal;