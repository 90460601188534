import React from 'react';
import {Link} from "react-router-dom";
import {statuses} from "../../view/Order/components/statuses";
import "./index.scss"
import moment from 'moment';

const ProfileOrderItem = ({order}) => {
    return (
        <Link to={`/o/${order.id}`}>
            <div className="profile-order" key={order.id}>
                <span className="profile-order__info">{moment(order.create_date).format('DD/MM/YYYY HH:mm')}</span>
                <h5 className="profile-order__address">{order.waste_geo_address}</h5>
                <span
                    className="profile-order__info">Строительные отходы. Примерный объем отходов куб. м: {order.waste_volume}</span>
                {order.price &&
                <h4 className="profile-order__price">{order.price / 100}р.</h4>
                }
                <span className={`status-label ${order.status.toLowerCase()}`}>
                   {statuses[order.status]}
                </span>
            </div>
        </Link>
    );
};

export default ProfileOrderItem;