import {AUTH_TOKEN, CODE_CONFIRMATION, GET_USER, LOGIN_ERROR, LOGIN_USER, LOGOUT} from "../constants";

const initialState = {
    token: localStorage.getItem(AUTH_TOKEN) || null,
    phone: "",
    success: false,
    user: {}
}

export const userReducer = (state = initialState, action) => {
    switch (action.type){
        case LOGIN_USER:
            return {...state, token: action.payload, phone: action.phone}
        case CODE_CONFIRMATION:
            return {...state, success: true, error: ""}
        case GET_USER:
            return {...state, user: action.payload, success: true}
        case LOGIN_ERROR:
            return {...state, error: action.payload}
        case LOGOUT:
            return initialState
        default:
            return state
    }
}