import React from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {PaymentReject, PaymentSuccess} from "../../components/svgImage";
import "./index.scss"

const PaymentStatus = () => {
    const history = useHistory()
    const {pathname} = useLocation()
    const path = pathname.slice(1)
    return (
        <div className="container payment__container">
            <div className="payment-status">
                <div className={`icon ${path}`}>
                    {path === "payment_success" ? <PaymentSuccess/> : <PaymentReject/>}
                </div>
                <h2 className="payment-status__title">{path === "payment_success" ? "Оплата прошла успешно" : "Что-то пошло не так"}</h2>
                <p className="payment-status__desc">{path === "payment_success" ? "В ближайшее время с Вами свяжется менеджер для уточнения деталей" : "Можете повторить попытку оплаты заявки"}</p>
                <button className="btn payment-status__btn" onClick={() => history.push("/")}>
                    На главную
                </button>
            </div>
        </div>
    );
};

export default PaymentStatus;