import React from 'react';



/** Контейнер для инпутов, с пропсом required, добавит звездочку, иначе просто скроет ее,
 * чтобы осталось такое же пространство */
const InputContainer = ({children, required}) => {
    return (
        <div style={{display: "flex", gap: '5px', width: '100%' }}>
            <div style={{flex: '1 1 auto'}}>{children}</div>
            <span style={{color: '#ff5555', flex: '0 1 auto', opacity: required ? 1 : 0}}>*</span>
        </div>
    );
};

export default InputContainer;