import {createRequest, APIService} from './APIService'


const FormApiData = new Map([
    [1, APIService.REQUESTS.ORDER_CREATE_FROM_PORTAL_FL],
    [2, APIService.REQUESTS.ORDER_CREATE_FROM_PORTAL_IP],
    [3, APIService.REQUESTS.ORDER_CREATE_FROM_PORTAL_UL],
])

export const sendForm = async (formData, applicant) => {
    await createRequest(FormApiData.get(applicant), {
      body: formData
    })
}

export const getOrder = async (id) => {
    const {data} = await createRequest(APIService.REQUESTS.ORDER_GET, {
        routeReplaceFlags: { '{{ID}}': id }
    })
    return data
}

export const getStaticFiles = async (clientType) => {
    const {data} = await createRequest(APIService.REQUESTS.STATIC_DOCUMENT_GET, {
        requestOptions: {
            params: {
                accessibility: clientType
            }
        }
    });
    return data
}