import React from 'react';
import {Link} from "react-router-dom";


const NotFound = () => {
    return (
        <section className="not-found">
            <div className="container">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb__item"><Link to="/">Главная</Link></li>
                    </ol>
                </nav>
                <h2 className="section-title">Страница не найдена...</h2>
            </div>
        </section>
    )
};

export default NotFound;
