import React from 'react';
import mnp from "../../assets/images/MNP.png";
import visa from "../../assets/images/Visa.png";
import masterCard from "../../assets/images/MasterCard.png";
import jbc from "../../assets/images/JCB.png";
import {Link} from "react-router-dom";

const Cards = () => {
    return (
        <div className="cards">
            <Link to="#" className="cards__link">
                <img src={mnp} alt="" className="cards__img" />
            </Link>
            <Link to="#" className="cards__link">
                <img src={visa} alt="" className="cards__img" />
            </Link>
            <Link to="#" className="cards__link">
                <img src={masterCard} alt="" className="cards__img" />
            </Link>
            <Link to="#" className="cards__link">
                <img src={jbc} alt="" className="cards__img" />
            </Link>
        </div>
    );
};

export default Cards;