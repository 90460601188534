import React from 'react';
import {DangerSVG, SuccessSVG} from "../svgImage";
import Modal from "../Modal";

const ResponseFormModal = ({title, desc, success = true, btnText, isOpen, setIsOpen}) => {
    return (
        <Modal isOpen={isOpen} className="sent">
            <div className={`sent__icon ${!success && "danger"}`}>
                {success ?   <SuccessSVG/> :   <DangerSVG />}
            </div>
            <h2 className="sent__title">{title}</h2>
            <p className="sent__desc">{desc}</p>
            <button className="btn sent__btn popup-close" onClick={() => setIsOpen(false)}>
                {btnText}
            </button>
        </Modal>
    );
};

export default ResponseFormModal;