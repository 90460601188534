export const generateDateTime = (dateTime) => `${dateTime.slice(0, 10).split("-").join("-")} ${dateTime.slice(11, 16).split("-").join("-")}`

/** Получение маски инн по типу лица
 * @param applicant {number}  тип лица (ФЛ - 1, ИП - 2, ЮЛ - 3)
 * @returns {string} маска для react-input-mask
 * */
export const getInnMask = (applicant) => '9'.repeat(applicant === 3 ? 10 : 12)

export const brPhoneMaskProperties = {
    mask: "+7 (999) 999-99-99",
    minLength: 18,
    maxLength: 18,
};

export const APP_STORE = "https://apps.apple.com/ru/app/стройвывоз/id1582379817?l=en"
export const PLAY_MARKET = "https://play.google.com/store/apps/details?id=ru.tkoinform.ossigwastesclient"