import React from 'react';

const InputError = ({error, touched}) => {
    return (
        <>
            {error && touched &&
            <span className="form__error">{error}</span>}
        </>
    );
};

export default InputError;