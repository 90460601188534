import { GET_FL_STATIC_FILES, GET_UL_STATIC_FILES, GET_WASTE_PASSPORT_SAMPLE_FILE } from "../constants";

const initialState = {
    fl: [],
    ul: [],
    wastePassportSample: null
}

export const staticFilesReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_FL_STATIC_FILES:
            return {...state, fl: action.payload}
        case GET_UL_STATIC_FILES:
            return {...state, ul: action.payload}
        case GET_WASTE_PASSPORT_SAMPLE_FILE:
            return {...state, wastePassportSample: action.payload}
        default:
            return state
    }
}