import React from 'react';
import InputContainer from "../common/InputContainer";
import {SelectSVG} from "../svgImage";

const Select = ({options, placeholder, value, onChange, name, onBlur, required}) => {
    return (
        <>
            <div className="form__control-wrapper">
                <InputContainer required={required}>
                    <select className={"form__control" + (!value ? ' none-selected' : '')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                    >
                        <option disabled hidden value="">{placeholder}</option>
                        {options.map(option => <option value={option.value}>{option.label}</option>)}
                    </select>
                    <SelectSVG/>
                </InputContainer>
            </div>
        </>
    );
};

export default Select;