import React from 'react'
import bunker1 from '../../assets/images/bunker-1.png'
import bunker2 from '../../assets/images/bunker-2.png'
import bunker3 from '../../assets/images/bunker-3.png'
import bunker4 from '../../assets/images/bunker-4.png'
import wave from '../../assets/images/mountain.svg'
import gradientLine from '../../assets/images/gradient-line.png'
import RequestSection from '../../components/RequestSection'
import { isIOS, isMacOs } from 'react-device-detect'
import {
    CheckSVG,
    ChartSVG,
    SendSVG,
    WalletSVG,
    ArrowRightSvg,
    LongArrowSVG,
    GraphSVG,
    TimeSVG,
    ShieldSVG, CaseSVG, SquareSVG, PlaneSVG, InfoSVG
} from '../../components/svgImage'
import Cards from "../../components/Cards";
import {APP_STORE, PLAY_MARKET} from "../../lib";

const Home = () => {
    return (
        <>
            <section className="hero">
                <div className="container hero__container">
                    <div className="hero__content">
                        <h2 className="hero__subtitle">
                            удобный сервис для
                        </h2>
                        <h1 className="hero__title" data-aos="zoom-in">
                            Вывоза отходов
                        </h1>
                        <h2 className="hero__subtitle">
                            от строительных и ремонтных работ
                        </h2>
                    </div>
                    <div className="hero__advantages">
                        <ul className="advantages-list">
                            <li className="advantages-list__item">
                                <div className="advantages-list__icon">
                                    <CaseSVG/>
                                </div>
                                <p>Работаем с физическими и юридическими лицами</p>
                            </li>
                            <li className="advantages-list__item">
                                <div className="advantages-list__icon">
                                    <SquareSVG/>
                                </div>
                                <p>Фиксированная стоимость услуги</p>
                            </li>
                            <li className="advantages-list__item">
                                <div className="advantages-list__icon">
                                    <PlaneSVG/>
                                </div>
                                <p>Вывоз на следующий день</p>
                            </li>
                            <li className="advantages-list__item">
                                <div className="advantages-list__icon">
                                    <InfoSVG/>
                                </div>
                                <p>SMS уведомления по мере выполнения заказа</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="category">
                <div className="container category__container">
                    <h2 className="section-title category__title">КАТЕГОРИИ СТРОИТЕЛЬНЫХ ОТХОДОВ</h2>
                    <div className="category__row">
                        <div className="category__col">
                            <div className="category__box">
                                <div className="category__icon">
                                    <CheckSVG/>
                                </div>
                                Битый кирпич, остатки бетона, штукатурка
                            </div>
                        </div>
                        <div className="category__col">
                            <div className="category__box">
                                <div className="category__icon">
                                    <CheckSVG/>
                                </div>
                                Части металлических элементов, обломки арматур
                            </div>
                        </div>
                        <div className="category__col">
                            <div className="category__box">
                                <div className="category__icon">
                                    <CheckSVG/>
                                </div>
                                Обрезки линолеума, настенное/напольное покрытие
                            </div>
                        </div>
                        <div className="category__col">
                            <div className="category__box">
                                <div className="category__icon">
                                    <CheckSVG/>
                                </div>
                                Упаковка от&nbsp;материалов (монтажной пены)
                            </div>
                        </div>
                        <div className="category__col">
                            <div className="category__box category__box-short">
                                <div className="category__icon">
                                    <CheckSVG/>
                                </div>
                                Пленка, картонные коробки, мешки
                            </div>
                        </div>
                        <div className="category__col">
                            <div className="category__box category__box-short">
                                <div className="category__icon">
                                    <CheckSVG/>
                                </div>
                                Отделочный материал
                            </div>
                        </div>
                        <div className="category__col">
                            <div className="category__box category__box-short">
                                <div className="category__icon">
                                    <CheckSVG/>
                                </div>
                                Двери/окна
                            </div>
                        </div>
                        <div className="category__col">
                            <div className="category__box category__box-short">
                                <div className="category__icon">
                                    <CheckSVG/>
                                </div>
                                Проводка, выключатели, розетки
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services">
                <div className="container services__container">
                    <div className="services__row">
                        <div className="services__col" data-aos="zoom-out-right">
                            <h2 className="section-title services__heading">
                                УСЛУГА ВЫВОЗА <br/> СТРОИТЕЛЬНЫХ ОТХОДОВ
                            </h2>
                        </div>
                        <div className="services__col">
                            <div className="services__row">
                                <div className="services__col services__col-bunker">
                                    <img src={bunker1} alt=""/>
                                    <div className="services__overlay">
                                        <p>8 м³</p>
                                    </div>
                                </div>
                                <div className="services__col services__col-bunker">
                                    <img src={bunker2} alt=""/>
                                    <div className="services__overlay">
                                        <p>20 м³</p>
                                    </div>
                                </div>
                                <div className="services__col services__col-bunker">
                                    <img src={bunker3} alt=""/>
                                    <div className="services__overlay">
                                        <p>27 м³</p>
                                    </div>
                                </div>
                                <div className="services__col services__col-bunker">
                                    <img src={bunker4} alt=""/>
                                    <div className="services__overlay">
                                        <p>36 м³</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="section-title services__heading services__benefits">
                        Почему выбирают нас
                    </h2>
                    <div className="benefits" data-aos="zoom-out-up">
                        <div className="benefits__item">
                            <div className="benefits__icon">
                                <GraphSVG/>
                            </div>
                            <p className="benefits__content">
                                Удобная оплата и <span>фиксированный тариф</span>.
                            </p>
                        </div>
                        <div className="benefits__item">
                            <div className="benefits__icon">
                                <TimeSVG/>
                            </div>
                            <p className="benefits__content">
                                Эффективная логистика: <span>точно в срок</span>.
                            </p>
                        </div>
                        <div className="benefits__item">
                            <div className="benefits__icon">
                                <ShieldSVG/>
                            </div>
                            <p className="benefits__content">
                                Наличие <span>лицензии</span> на обращение с отходами.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="work">
                <div className="container work__container">
                    <h2 className="section-title work__title">КАК МЫ РАБОТАЕМ</h2>
                    <div className="work__row owl-carousel">
                        <div className="work__col">
                            <div className="work__arrow-box">
                                <div className="work__icon">
                                    <SendSVG/>
                                </div>
                                <LongArrowSVG/>
                            </div>
                            <h4 className="work__col-title">Заявка</h4>
                            <p className="work__col-desc">
                                Заполните <a href="/#request">онлайн форму</a> на сайте,
                                в <a href={isIOS || isMacOs ? APP_STORE : PLAY_MARKET} target="_blank">мобильном приложении</a> <br/>
                                или позвоните <a href="tel:+78005000865">8 800 500 0865</a>
                            </p>
                        </div>
                        <div className="work__col">
                            <div className="work__arrow-box">
                                <div className="work__icon">
                                    <ChartSVG/>
                                </div>
                                <LongArrowSVG/>
                            </div>
                            <h4 className="work__col-title">Расчет стоимости</h4>
                            <p className="work__col-desc">
                                Сотрудник контакт-центра поможет с оценкой объема отходов и рассчитает стоимость.
                            </p>
                        </div>
                        <div className="work__col">
                            <div className="work__arrow-box">
                                <div className="work__icon">
                                    <WalletSVG/>
                                </div>
                                <LongArrowSVG/>
                            </div>
                            <h4 className="work__col-title">Оплата</h4>
                            <p className="work__col-desc">
                                Вам придет SMS со ссылкой на оплату онлайн через Сбербанк с использованием банковской
                                карты (для физических лиц).<br/><br/>
                                Для юридических лиц оплата происходит по договору.
                            </p>
                            <Cards/>
                        </div>
                        <div className="work__col">
                            <div className="work__icon">
                                <ArrowRightSvg/>
                            </div>
                            <h4 className="work__col-title">Выполнение услуг</h4>
                            <p className="work__col-desc">
                                Уведомляем о ходе выполнения заказа через SMS.<br/>
                                В день вывоза вам необходимо подготовить отходы к вывозу.
                            </p>
                        </div>
                    </div>
                    <div className="btn__container">
                        <a href="#request" className="request-btn work__btn">
                            Оставить заявку
                        </a>
                    </div>
                </div>
                <div className="work__wave">
                    <img src={wave} alt=""/>
                </div>
            </section>
            <section className="plans" id="plans">
                <div className="container plans__container">
                    <h2 className="section-title plans__title">Тариф</h2>
                    <div className="plans__row" data-aos="zoom-out-down">
                        <div className="plans__col">
                            <div className="plans__item">
                                <div className="plans__item-header">
                                    <img src={bunker1} alt="" className="plans__item-img"/>
                                </div>
                                <h6 className="plans__item-money">9 500 ₽</h6>
                                <p className="plans__item-size">За бункер 8м³</p>
                            </div>
                            <img className="plans__underside-img" src={gradientLine} alt=""/>
                        </div>
                        <div className="plans__col">
                            <div className="plans__item">
                                <div className="plans__item-header">
                                    <img src={bunker2} alt="" className="plans__item-img"/>
                                </div>
                                <h6 className="plans__item-money">22 000 ₽</h6>
                                <p className="plans__item-size">За бункер 20м³</p>
                            </div>
                            <img className="plans__underside-img" src={gradientLine} alt=""/>
                        </div>
                        <div className="plans__col">
                            <div className="plans__item">
                                <div className="plans__item-header">
                                    <img src={bunker2} alt="" className="plans__item-img"/>
                                </div>
                                <h6 className="plans__item-money">25 000 ₽</h6>
                                <p className="plans__item-size">За бункер 27м³</p>
                            </div>
                            <img className="plans__underside-img" src={gradientLine} alt=""/>
                        </div>
                        <div className="plans__col">
                            <div className="plans__item">
                                <div className="plans__item-header">
                                    <img src={bunker2} alt="" className="plans__item-img"/>
                                </div>
                                <h6 className="plans__item-money">30 500 ₽</h6>
                                <p className="plans__item-size">За бункер 36м³</p>
                            </div>
                            <img className="plans__underside-img" src={gradientLine} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <RequestSection/>
        </>
    )
}

export default Home
