import React from 'react';
import {statuses} from "./statuses"
const OrderStatus = ({status}) => {
    return (
        <span className={`order__status ${status.toLowerCase()}`}>
            {statuses[status]}
        </span>
    );
};

export default OrderStatus;