import Routers from "./router"
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getUserData} from "./redux/actions/userActions";

function App() {
    const dispatch = useDispatch()

    useEffect(() => {
        const isProfilePage = window.location.pathname.split('/').includes('profile')
        if (!isProfilePage) {
            dispatch(getUserData())
        }
    }, [dispatch])
    return (
        <Routers />
    )
}

export default App
