import React, {useState} from 'react';
import Cards from "../../components/Cards";
import {CollapseSVG} from "../../components/svgImage";

const Payment = () => {
    const [isCollapsed, setIsCollapsed] = useState(0)
    const handleCollapse = (tabId) => {
        setIsCollapsed(isCollapsed === tabId ? 0 : tabId)
    }
    return (
        <section className="payment">
            <div className="container payment__container">
                <h2 className="section-title">Оплата сделки</h2>
                <div className="about__contacts contacts payment_table">
                    <div className="contacts__item">
                        <div className="contacts__row">
                            <h5 className="contacts__title">Стоимость</h5>
                            <p className="order__id">24 000 ₽</p>
                        </div>
                    </div>
                    <div className="contacts__item payment__item">
                        <div className="contacts__row">
                            <h5 className="contacts__title">Организация</h5>
                            <p className="contacts__info payment__info">123</p>
                        </div>
                        <div className="contacts__row">
                            <h5 className="contacts__title">ИНН/КПП</h5>
                            <p className="contacts__info payment__info">252551152/235324</p>
                        </div>
                    </div>
                    <div className="contacts__item payment__item">
                        <div className="contacts__row">
                            <h5 className="contacts__title">Счет номер</h5>
                            <p className="contacts__info payment__info">4070 2810501 4600 00747</p>
                        </div>
                        <div className="contacts__row">
                            <h5 className="contacts__title">Банк</h5>
                            <p className="contacts__info payment__info">ПАО “Сбербанк”, г.Москва БИК 044525411</p>
                        </div>
                    </div>
                </div>
                <a href="#" className="contacts__download payment__download">Скачать реквизиты</a>
                <Cards/>
                <a href="#" className="btn">Оплатить</a>
                <div className="collapse">
                        <button type="button" onClick={() => handleCollapse(1)}>
                            <span>Процедура возврата денег</span>
                            <CollapseSVG className={isCollapsed === 1 && "collapseArrow"}/>
                        </button>
                        <button type="button" onClick={() => handleCollapse(2)}>
                            <span>Подробное описание услуги</span>
                            <CollapseSVG className={isCollapsed === 2 && "collapseArrow"}/>
                        </button>
                    {
                        isCollapsed === 1 &&
                        <div className="collapse__content">
                            <p> sit amet, consectetur adipiscing elit. Ornare sollicitudin quisque ut
                                arcu sodales risus fames. Morbi aliquam ornare suspendisse ut ultrices. Interdum aliquam
                                volutpat nisl enim scelerisque commodo, urna urna purus. In gravida justo pharetra, at
                                amet, risus urna, consectetur. Mauris ultrices feugiat lorem vulputate morbi tristique
                                iaculis diam elit. Gravida risus egestas nisi eget in. Ut tincidunt commodo tortor, non
                                posuere purus. Orci augue nulla aliquam, eu et. Nulla magna vel vulputate commodo mattis
                                libero leo, diam.</p>
                            <p>Mauris et molestie auctor pellentesque quis. Auctor arcu quis bibendum diam ut. Accumsan
                                vestibulum sagittis, dignissim et tempus fermentum nisl, pellentesque pellentesque.
                                Dictum urna nunc elementum amet eget sit turpis. Vivamus iaculis porttitor donec auctor
                                sit neque, purus. Condimentum velit tellus ultrices mi. Orci et auctor eu at nibh
                                aliquet.</p>
                            <p>Habitasse mattis ridiculus ultrices sapien aliquam cursus mi sit nunc. Commodo id risus
                                faucibus enim enim, eleifend vitae. Nec etiam elit semper in justo aenean. </p>
                        </div>
                    }
                    {
                        isCollapsed === 2 &&
                        <div className="collapse__content">
                            <p>Dolor sit amet, consectetur adipiscing elit. Ornare sollicitudin quisque ut
                                arcu sodales risus fames. Morbi aliquam ornare suspendisse ut ultrices. Interdum aliquam
                                volutpat nisl enim scelerisque commodo, urna urna purus. In gravida justo pharetra, at
                                amet, risus urna, consectetur. Mauris ultrices feugiat lorem vulputate morbi tristique
                                iaculis diam elit. Gravida risus egestas nisi eget in. Ut tincidunt commodo tortor, non
                                posuere purus. Orci augue nulla aliquam, eu et. Nulla magna vel vulputate commodo mattis
                                libero leo, diam.</p>
                            <p>Mauris et molestie auctor pellentesque quis. Auctor arcu quis bibendum diam ut. Accumsan
                                vestibulum sagittis, dignissim et tempus fermentum nisl, pellentesque pellentesque.
                                Dictum urna nunc elementum amet eget sit turpis. Vivamus iaculis porttitor donec auctor
                                sit neque, purus. Condimentum velit tellus ultrices mi. Orci et auctor eu at nibh
                                aliquet.</p>
                            <p>Habitasse mattis ridiculus ultrices sapien aliquam cursus mi sit nunc. Commodo id risus
                                faucibus enim enim, eleifend vitae. Nec etiam elit semper in justo aenean. </p>
                        </div>
                    }
                    </div>
            </div>
        </section>
    );
};

export default Payment;
