import React, { useState, useCallback, useEffect, useRef } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { createRequest, APIService } from '../../services/APIService';
import InputError from "../InputError";


const Address = ({setFieldValue, error, touched, onBlur}) => {
    const [mapPoints, onChangeMapPoints] = useState({
        address: "",
        coords: [55.7507, 37.5956]
    });
    const addressInput = useRef();
    const [activePolygons, setActivePolygons] = useState([]);
    const [localError, setLocalError] = useState(null);
    const [mapRef, setMapRef] = useState(null);
    const [ymapRef, setYmapRef] = useState(null);
    const [suggestView, setSuggestView] = useState(null)
    useEffect(() => {
      if(!mapRef && !ymapRef) return;
      createRequest(APIService.REQUESTS.LOAD_ACTIVE_MULTIPOLYGONS)
        .then(res => res.data.records)
        .then(zones => zones.flatMap(zone => zone.coordinates.map((_polygon) => {
          const polygon = new global.ymaps.Polygon(
            _polygon.map(sector => sector.map(coords => coords.reverse())), 
            { hintContent: zone.name },
            {
              fillColor: 'rgba(0, 83, 181, 0.2)',
              strokeWidth: 0,
              interactivityModel: 'default#transparent',
            }
          )
          return polygon;
        })))
        .then(polygons => {     
          polygons.forEach(polygon => mapRef.geoObjects.add(polygon));
          setActivePolygons(polygons);
        })
        .catch(err => console.log(`Error while loading active zones`, err));
    }, [mapRef, ymapRef])
    
    const onMapAddressChange = useCallback((address, coords) => {
      const isPointInActiveZones = activePolygons
        .map(p => p.geometry.contains(coords))
        .includes(true);
      if(isPointInActiveZones){
        setLocalError(null);
        onChangeMapPoints({...mapPoints, address, coords})
        addressInput.current.value = address
      } else {
        setLocalError('Данный адрес не обслуживается')
      }
    }, [activePolygons])

    const getCoords = (address) => {
      return global.ymaps
        .geocode(address)
        .then(result => 
          result.geoObjects.get(0).geometry.getCoordinates()
        );
    }

    const handleSave = (data) => {
      if (data) {
          getCoords(data)
            .then(coords => coords && 
              onMapAddressChange(data, coords)
            );
      }
    }

    const handleSelect = (e) => {
      handleSave(e.get("item").displayName)
    }

    const onLoad = (ymaps) => {
      if(ymaps) {
        setSuggestView(new ymaps.SuggestView('suggestYmap', {
          provider: {
            suggest: (request) => global.ymaps.suggest("Россия, " + request)
          }
        }));
        setYmapRef(ymaps);
      }
    }

    useEffect(() => {
      suggestView && suggestView.events.add('select', handleSelect)
      return () => suggestView && suggestView.events.remove('select', handleSelect)
    }, [suggestView, activePolygons])

    useEffect(() => {
        (async() => {
            await setFieldValue("waste_geo_address", mapPoints.address)
            await setFieldValue("waste_geo_latitude", mapPoints.coords[0] || "")
            await setFieldValue("waste_geo_longitude", mapPoints.coords[1] || "")
        })()
    }, [mapPoints, setFieldValue])

    const getAddress = (coords) => {
      global.ymaps.geocode(coords).then((res) => {
        const firstGeoObject = res.geoObjects.get(0);
        const address = [
          firstGeoObject.getLocalities().length
              ? firstGeoObject.getLocalities()
              : firstGeoObject.getAdministrativeAreas(),
          firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
          firstGeoObject.getPremiseNumber()
        ]
          .filter(Boolean)
          .join(", ");
          onMapAddressChange(address, coords)
      });
    }
    return (
      <div>
          <input type="text"
                  id="suggestYmap"
                  name="waste_geo_address"
                  placeholder="Введите адрес"
                  className="form__control"
                  ref={addressInput}
                  onBlur={onBlur}
          />
          <InputError
              error={localError || error}
              touched={touched || !!localError}
          />
          <div style={{marginTop: "20px"}}>
              <YMaps
                  query={{
                      apikey: '744d33c1-034f-4dac-9ff2-bdfe1f52b51f',
                  }}
              >
                  <Map
                      instanceRef={ref => {
                        if (ref) setMapRef(ref)
                      }}
                      onLoad={onLoad}
                      modules={['SuggestView', 'Polygon', 'suggest', 'geocode']}
                      onClick={e => getAddress(e.get('coords'))}
                      width={"100%"}
                      height={300}
                      options={{
                        suppressMapOpenBlock: true,
                      }}
                      state={{
                          center: mapPoints.coords,
                          zoom: 10
                      }}
                  > 
                      <Placemark
                          geometry={mapPoints.coords}
                      />
                  </Map>
              </YMaps>
          </div>
      </div>
  );
};

export default Address;
