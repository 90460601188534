import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import moment from 'moment'
import PrivacyConfirmation from '../PrivacyComfirmation'
import { sendForm } from '../../services/requests'
import { CalendarSVG } from '../svgImage'
import ReactDadataBox from 'react-dadata-box'
import InputMask from 'react-input-mask'
import Dropzone from '../Dropzone'
import Address from '../Address'
import ResponseFormModal from '../ResponseFormModal'
import footerWave from '../../assets/images/footer-wave.png'
import { brPhoneMaskProperties, getInnMask } from '../../lib'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { validationSchema } from './validationSchema'
import InputError from '../InputError'
import { useSelector } from 'react-redux'
import InputContainer from '../common/InputContainer'
import APIService, { createRequest } from '../../services/APIService'
import Select from '../Select'

registerLocale('ru', ru)

const RequestSection = () => {
  const [photos, setPhotos] = useState([])
  const [documents, setDocuments] = useState([])
  const [applicant, setApplicant] = useState(1)
  const [vehicleTypes, setVehicleTypes] = useState([])
  const [successModal, setSuccessModal] = useState(false)
  const [dangerModal, setDangerModal] = useState(false)

  useEffect(() => {
    createRequest(APIService.REQUESTS.LOAD_VEHICLE_TYPE)
      .then((res) => res.data)
      .then((rec) => setVehicleTypes(rec))
      .catch((err) => console.log('Couldn`t load vehicle types', err))
  }, [])

  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    isSubmitting,
    isValid,
    setFieldValue,
    setTouched
  } = useFormik({
    initialValues: {
      comment: '',
      waste_removal_day_period: '',
      waste_type_id: 1,
      waste_volume: '',
      first_name: '',
      last_name: '',
      phone: '',
      communication_type: 'PHONE',
      waste_geo_address: '',
      waste_geo_latitude: '',
      waste_geo_longitude: '',
      photos: [],
      waste_removal_date: '',
      need_loader: false,
      has_barrier: false,
      email: null,
      privacyConfirmed: false,
      company_name: '',
      inn: '',
      company_address_reg: '',
      documents: 0
    },
    validationSchema: Yup.object().shape(validationSchema(applicant)),
    onSubmit: async (values) => {
      values = {
        ...values,
        photos,
        documents,
        waste_volume: +values.waste_volume,
        phone: values.phone.replace(/[\D]+/g, ''),
        waste_removal_date: moment(values.waste_removal_date).format('yyyy-MM-DD')
      }
      if (!values.email || values.email.length < 1) delete values.email
      try {
        await sendForm(values, applicant)
        await setTouched({})
        setSuccessModal(true)
      } catch (e) {
        setDangerModal(true)
      }
    }
  })

  const hours = new Date().getHours()
  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + (hours > 15 ? 8 : 7))
  const minDate = new Date()
  minDate.setDate(minDate.getDate() + (hours > 15 ? 2 : 1))

  useEffect(() => {
    setTouched({})
  }, [applicant, setTouched])

  useEffect(() => {
    setFieldValue('documents', documents.length)
  }, [documents.length])

  const wastePassportSample = useSelector((state) => {
    return state.staticFiles.wastePassportSample
  })

  const [isInnSelectedFromSuggestions, setIsInnSelectedFromSuggestions] = useState(false)

  const wasteRemovalDayPeriodOptions = [
    {
      label: '9:00-13:00',
      value: '9.00-13.00'
    },
    {
      label: '13:00-17:00',
      value: '13.00-17.00'
    },
    {
      label: '17:00-21:00',
      value: '17.00-21.00'
    }
  ]

  return (
    <>
      <section className="request" id="request">
        <div className="container request__container">
          <h2 className="section-title request__title">Создание заявки</h2>
          <div className="request__info">
            <h5 className="request__description">
              Заполните заявку или свяжитесь с оператором по номеру
              <span> 8 800 500 08 65</span>
            </h5>
            <p className="request__additional">
              (принимаем звонки с 9:00 до 21:00 без перерыва и выходных)
            </p>
          </div>
          <div className="applicant">
            <div className="applicant__item">
              <input
                type="radio"
                className="applicant__input"
                id="physical"
                name="applicant"
                onChange={() => setApplicant(1)}
                defaultChecked={true}
              />
              <label htmlFor="physical">Физическое лицо</label>
            </div>
            <div className="applicant__item">
              <input
                type="radio"
                className="applicant__input"
                id="individual"
                name="applicant"
                onChange={() => setApplicant(2)}
              />
              <label htmlFor="individual">Индивидуальный предприниматель</label>
            </div>
            <div className="applicant__item">
              <input
                type="radio"
                className="applicant__input"
                id="entity"
                name="applicant"
                onChange={() => setApplicant(3)}
              />
              <label htmlFor="entity">Юридическое лицо</label>
            </div>
          </div>
          <form className="form request__form" onSubmit={handleSubmit}>
            <div className="form__row">
              <div className="form__col">
                <InputContainer required>
                  <input
                    type="text"
                    name="first_name"
                    placeholder={applicant === 3 ? 'Имя представителя' : 'Имя'}
                    className="form__control"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputContainer>
                <InputError error={errors.first_name} touched={touched.first_name} />
              </div>
              <div className="form__col">
                <InputContainer required>
                  <input
                    type="text"
                    name="last_name"
                    placeholder={applicant === 3 ? 'Фамилия представителя' : 'Фамилия'}
                    className="form__control"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputContainer>
                <InputError error={errors.last_name} touched={touched.last_name} />
              </div>
            </div>
            {applicant !== 1 && (
              <div className="entity_form">
                <div className="form__row">
                  <div className="form__col">
                    <InputContainer required>
                      <ReactDadataBox
                        token="bccf01d44b841d082101c119c72601851e259396"
                        type="party"
                        name="inn"
                        onChange={async ({ data, value }) => {
                          await setFieldValue('company_name', value)
                          await setFieldValue('company_address_reg', data.address?.value || '')
                          await setFieldValue('inn', data?.inn || '')
                          setIsInnSelectedFromSuggestions(true)
                        }}
                        payloadModifier={(payload) => {
                          return {
                            ...payload,
                            query: payload.query?.split('_')[0] || ''
                          }
                        }}
                        customInput={(props) => {
                          return (
                            <InputMask
                              name="inn"
                              onChange={(ev) => {
                                handleChange(ev)
                                props.onChange(ev)
                                isInnSelectedFromSuggestions &&
                                  setIsInnSelectedFromSuggestions(false)
                              }}
                              mask={getInnMask(applicant)}
                              type="text"
                              className="form__control"
                              placeholder="ИНН"
                              value={values.inn}
                              onBlur={handleBlur}
                            >
                              <input onBlur={handleBlur} />
                            </InputMask>
                          )
                        }}
                        allowClear
                      />
                    </InputContainer>
                    <InputError error={errors.inn} touched={touched.inn} />
                  </div>
                  <div className="form__col">
                    <InputContainer required>
                      <input
                        type="text"
                        name="company_address_reg"
                        className="form__control"
                        placeholder="Адрес для обмена документами"
                        value={values.company_address_reg}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!(values.inn.length === 10 || values.inn.length === 12)}
                      />
                    </InputContainer>
                    <InputError
                      error={errors.company_address_reg}
                      touched={touched.company_address_reg}
                    />
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__col">
                    <InputContainer required>
                      <input
                        type="text"
                        name="company_name"
                        className="form__control"
                        placeholder={applicant === 2 ? 'Наименование ИП' : 'Наименование юр.лица'}
                        value={values.company_name}
                        disabled
                      />
                    </InputContainer>
                    <InputError error={errors.company_name} touched={touched.company_name} />
                  </div>
                </div>
              </div>
            )}

            <div className="form__row">
              <div className="form__col">
                <InputContainer required>
                  <InputMask
                    type="text"
                    name="phone"
                    className="form__control"
                    placeholder="Номер телефона"
                    mask={brPhoneMaskProperties.mask}
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isOpen
                  >
                    <input onBlur={handleBlur} />
                  </InputMask>
                </InputContainer>
                <InputError error={errors.phone} touched={touched.phone} />
              </div>
              <div className="form__col">
                <InputContainer>
                  <input
                    type="email"
                    name="email"
                    className="form__control"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputContainer>
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>

            <h5 className="form__location">Что нужно вывезти, откуда и когда</h5>
            <div className="form__row">
              <div className="form__col">
                <InputContainer required>
                  <Address
                    setFieldValue={setFieldValue}
                    error={errors.waste_geo_address}
                    touched={touched.waste_geo_address}
                    onBlur={handleBlur}
                  />
                </InputContainer>
              </div>
            </div>
            <div className="form__row">
              <div className="form__col">
                <Select
                  placeholder={'Примерный объем отходов'}
                  name={'waste_volume'}
                  value={values.waste_volume}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={vehicleTypes.map((item) => ({
                    value: item.volume,
                    label: `${item.volume} м³ - ${item.price / 100}₽`
                  }))}
                />
                <InputError error={errors.waste_volume} touched={touched.waste_volume} />
              </div>
              <div className="form__col">
                <div className="form__control-wrapper">
                  <InputContainer required>
                    <DatePicker
                      name="waste_removal_date"
                      placeholderText="Выберите дату"
                      onChange={(date) => setFieldValue('waste_removal_date', date || '')}
                      selected={values.waste_removal_date}
                      dateFormat="dd.MM.yyyy"
                      locale="ru"
                      maxDate={maxDate}
                      minDate={minDate}
                      disabledKeyboardNavigation
                      className="form__control"
                      autoComplete="off"
                      onBlur={handleBlur}
                    />
                  </InputContainer>
                  <InputError
                    error={errors.waste_removal_date}
                    touched={touched.waste_removal_date}
                  />
                  <CalendarSVG className={'icon--calendar'} />
                </div>
              </div>
              <div className="form__col">
                <Select
                  name="waste_removal_day_period"
                  value={values.waste_removal_day_period}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={'Время'}
                  options={wasteRemovalDayPeriodOptions}
                  required
                />
                <InputError
                  error={errors.waste_removal_day_period}
                  touched={touched.waste_removal_day_period}
                />
              </div>
            </div>
            <div className="form__row">
              <div className="form__col">
                <input
                  name="has_barrier"
                  type="checkbox"
                  id="need_barrier"
                  className="form__checkbox"
                  value={values.has_barrier}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="need_barrier" className="form__checkbox-label">
                  есть шлагбаум
                </label>
              </div>
            </div>

            <div className={'mb-40'}>
              <InputContainer>
                <Dropzone files={photos} setFiles={setPhotos} accept="image/*" type="photo" />
              </InputContainer>
              {[2, 3].includes(applicant) && (
                <InputContainer required>
                  <Dropzone files={documents} setFiles={setDocuments} type="doc">
                    {wastePassportSample && (
                      <div className={'form__need-waste-passport-msg'}>
                        <a href={wastePassportSample.url} download>
                          Скачать {wastePassportSample.name}
                        </a>
                      </div>
                    )}
                  </Dropzone>
                </InputContainer>
              )}
              <InputError error={errors.documents} touched={touched.documents} />
            </div>

            <div className="form__row">
              <div className="form__col">
                <InputContainer>
                  <textarea
                    name="comment"
                    cols="30"
                    rows="5"
                    className="form__control"
                    placeholder="Комментарий"
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputContainer>
              </div>
            </div>

            <InputContainer>
              <div className="form__footer">
                <PrivacyConfirmation
                  isFL={applicant === 1}
                  error={errors.privacyConfirmed}
                  touched={touched.privacyConfirmed}
                  value={values.privacyConfirmed}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <button
                  type="submit"
                  className="form__btn"
                  disabled={
                    !isValid || isSubmitting || (applicant !== 1 && !isInnSelectedFromSuggestions)
                  }
                >
                  Оставить заявку
                </button>
              </div>
            </InputContainer>
          </form>
        </div>
        <div className="request__wave">
          <img src={footerWave} alt="" />
        </div>
      </section>
      <ResponseFormModal
        title="Заявка успешно отправлена"
        desc="Наш менеджер свяжется с вами в ближайшее время"
        btnText="Отлично"
        isOpen={successModal}
        setIsOpen={setSuccessModal}
      />
      <ResponseFormModal
        title="Произошла ошибка"
        desc="Можете повторить попытку подачи заявки"
        success={false}
        btnText="Закрыть"
        isOpen={dangerModal}
        setIsOpen={setDangerModal}
      />
    </>
  )
}

export default RequestSection
