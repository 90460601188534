import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../components/Spinner";
import ProfileOrderItem from "../../components/ProfileOrderItem";
import {getUserData} from "../../redux/actions/userActions";

const Profile = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUserData())
    }, [dispatch]);

    const user = useSelector(store => store.user.user)
    if (!user.id) {
        return <Spinner/>
    }
    return (
        <div className="container">
            <h2 className="section-title">Мои заказы</h2>
            {
                user.orders.length ?
                    user.orders.sort((a, b) => b.id - a.id).map((order) => <ProfileOrderItem key={order.id} order={order} />)
                    : <p className="section-subtitle">Список пуст</p>
            }
        </div>
    );
};

export default Profile;