import { getStaticFiles } from "../../../redux/actions/staticFilesActions";
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { PaperClip } from '../../../components/svgImage';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CLIENT_TYPE } from "../../../redux/constants";
import APIService, { createRequest } from '../../../services/APIService';

const WastePassportArea = ({order_id, wastePassport, updateOrder}) => {

  const wastePassportTemplate = useSelector(state => state.staticFiles.wastePassportSample);
  const dispatch = useDispatch();

  useEffect(() => {
    getStaticFiles(CLIENT_TYPE.UL)(dispatch);
  }, []);

  const onDrop = (acceptedFiles) => {
    if(!acceptedFiles.length) return;
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.set('file', file);
    createRequest(APIService.REQUESTS.FILE_UPLOAD, {
      body: formData,
    }).then(({data: {url}}) => {
      createRequest(APIService.REQUESTS.ORDER_ATTACH_WASTE_PASSPORT, {
        body: { order_id, url }
      }).then(res => updateOrder())
      .catch(err => console.log(err))
    })
    .catch(err => console.log(err));
  }

  const { getRootProps, getInputProps } = useDropzone({ accept: '.docx', onDrop });
  return (
    <div className='waste-passport__container'>
      <div {...getRootProps({ className: 'waste-passport__dropzone' })}>
        <input {...getInputProps()} />
        <div className='waste-passport__input__text waste-passport__text'>{
          wastePassport ? 'Загрузить новый паспорт отходов' : 'Загрузить паспорт отходов'
        }</div>
      </div>
      <div className='waste-passport__attached-file'>
        <PaperClip className='waste-passport__attached-file__icon'/>
        <a className="waste-passport__text" href={wastePassportTemplate?.url}>Образец паспорта отходов</a>
      </div>
    </div>
  )  
}

export default WastePassportArea;