import {GET_UL_STATIC_FILES, GET_FL_STATIC_FILES, CLIENT_TYPE, WASTE_PASSPORT_SAMPLE_ID, GET_WASTE_PASSPORT_SAMPLE_FILE} from "../constants";
import { getStaticFiles as getStaticFilesApi } from '../../services/requests'

export const getStaticFiles = (clientType) => {
    return (dispatch) => {
        try {
            getStaticFilesApi(clientType)
                .then((data) => {
                    dispatch({type: clientType === CLIENT_TYPE.FL
                            ? GET_FL_STATIC_FILES
                            : GET_UL_STATIC_FILES, payload: data.filter(file => file.id !== WASTE_PASSPORT_SAMPLE_ID)});

                    const wastePassportSample = data.find(file => file.id === WASTE_PASSPORT_SAMPLE_ID)
                    if (wastePassportSample) {
                        dispatch({type: GET_WASTE_PASSPORT_SAMPLE_FILE, payload: wastePassportSample});
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }
}