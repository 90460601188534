import React from 'react';
import "./index.scss"

const Spinner = () => {
    return (
        <div className="self-building-square-spinner">
            <div className="square"/>
            <div className="square"/>
            <div className="square"/>
            <div className="square clear"/>
            <div className="square"/>
            <div className="square"/>
            <div className="square clear"/>
            <div className="square"/>
            <div className="square"/>
        </div>
    );
};

export default Spinner;