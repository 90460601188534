import { APIService, createRequest } from '../../services/APIService'
import {
  AUTH_TOKEN,
  CODE_CONFIRMATION,
  GET_USER,
  LOGIN_ERROR,
  LOGIN_USER,
  LOGOUT
} from '../constants'

export const userAuth = (phone) => {
  return (dispatch) => {
    try {
      createRequest(APIService.REQUESTS.CUSTOMER_REGISTER, {
        body: {
          phone: phone,
          customer_type: 'FL'
        }
      }).then(({ data }) => dispatch({ type: LOGIN_USER, payload: data.access_token, phone }))
    } catch (e) {
      dispatch({ type: LOGIN_ERROR, payload: e.result_message })
    }
  }
}

export const getUserData = () => {
  return (dispatch) => {
    if (localStorage.getItem(AUTH_TOKEN)) {
      createRequest(APIService.REQUESTS.CUSTOMER_GET).then(({ data }) =>
        dispatch({ type: GET_USER, payload: data })
      )
    }
  }
}

export const confirmCode = (code) => {
  return (dispatch, getState) => {
    createRequest(APIService.REQUESTS.CUSTOMER_VERIFY, {
      body: {
        phone: getState().user.phone,
        code: code
      },
      requestOptions: {
        headers: {
          Authorization: `Bearer ${getState().user.token}`,
          'Content-Type': 'application/json'
        }
      }
    }).then(({ data }) => {
      if (data.success) {
        dispatch({ type: CODE_CONFIRMATION })
        dispatch({ type: LOGIN_USER, payload: data.access_token, phone: getState().user.phone })
        localStorage.setItem(AUTH_TOKEN, getState().user.token)
        dispatch(getUserData())
      } else {
        dispatch({ type: LOGIN_ERROR, payload: 'Неверный код подтвержения' })
        setTimeout(() => dispatch({ type: LOGIN_ERROR, payload: '' }), 2000)
      }
    })
    return true
  }
}

export const logOut = () => {
  localStorage.removeItem(AUTH_TOKEN)
  return { type: LOGOUT }
}
