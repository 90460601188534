import React, { Fragment, useEffect } from 'react'

import { Link } from "react-router-dom";
import InputError from "../InputError";
import { getStaticFiles } from "../../redux/actions/staticFilesActions";
import { CLIENT_TYPE } from "../../redux/constants";
import { connect } from "react-redux";

const PrivacyConfirmation = ({touched, value, onChange, onBlur, error, files, getFiles, isFL = false}) => {

    useEffect(() => {
        if (isFL) {
            files.fl.length === 0 && getFiles(CLIENT_TYPE.FL)
            return
        }
        files.ul.length === 0 && getFiles(CLIENT_TYPE.UL)
    }, [isFL])


    return (
        <>
            <div className="confirm">
                <input
                    type="checkbox"
                    className="confirm__input"
                    id="term"
                    name="privacyConfirmed"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <label htmlFor="term" className="confirm__label">
                    <div>Даю согласие на обработку персональных данных оператором Агрегатора, Ознакомлен со следующими документами: {' '}
                    {(isFL ? files.fl : files.ul).map((document, index) => {

                        return <Fragment key={index}>
                            {index !== 0 && ', '}
                            {document.url ? (<Link key={document.id} to={{pathname: document.url}}
                                                   target="_blank">
                                {document.name}
                            </Link>) : <span>{document.name}</span>}

                        </Fragment>
                    })}
                    </div>
                </label>
                <InputError
                    error={error}
                    touched={touched}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    files: {
        fl: state.staticFiles.fl,
        ul: state.staticFiles.ul
    },
})

const mapDispatchToProps = (dispatch) => ({
    getFiles: (clientType) => dispatch(getStaticFiles(clientType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyConfirmation)
