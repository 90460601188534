import React, {useState} from 'react'
import logo from '../../assets/images/logo.jpg'
import {BurgerSVG, LocationSVG, PhoneSVG, PlusSVG, ProfileSVG} from '../svgImage'
import {Link, useHistory, useLocation} from "react-router-dom";
import {HashLink} from 'react-router-hash-link'
import "./style.scss"
import AuthModal from "../AuthModal";
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../redux/actions/userActions";

const Header = () => {
    const history = useHistory()
    const location = useLocation()
    const [showModal, setShowModal] = useState(false)
    const dispatch = useDispatch()
    const {user} = useSelector(store => store.user)

    const goToProfile = () => user?.id ? history.push("/profile") : setShowModal(true)

    return (
        <header className="header">
            <div className="header__container container">
                <div className="burger burger__open">
                    <span/>
                    <span/>
                    <span/>
                </div>
                <Link to="/" className="header__logo logo">
                    <img src={logo} alt=""/>
                </Link>
                <nav className="header__menu">
                    <div className="burger burger__close mobile-hidden">
                        <span/>
                        <span/>
                        <span/>
                    </div>
                    <a href="#" className="header__feedback-contacts header__feedback-location">
                        <LocationSVG/>
                        Московская область
                    </a>
                    <a href="tel:88002166510" className="header__feedback-contacts">
                        <PhoneSVG/>8 800 500 08 65
                    </a>

                    <div className="header__mobile-menu">
                        <HashLink to="/#plans" className="header__feedback-contacts header__feedback-location">
                            Тариф
                        </HashLink>
                        <HashLink to="/#request" className="header__feedback-contacts header__feedback-location">
                            Создание заявки
                        </HashLink>
                        <Link to="/consumer" className="header__feedback-contacts header__feedback-location">
                            Информация для потребителя
                        </Link>
                    </div>
                </nav>
                <div className="header__feedback">
                    <button className="header__btn btn btn-outline"
                            onClick={goToProfile}>
                        Личный кабинет
                    </button>
                    {location.pathname === "/profile" ?
                        <button className="header__btn btn btn-outline" onClick={() => {
                            dispatch(logOut())
                            history.push("/")
                        }}>
                            Выход
                        </button>
                        : <HashLink to="/#request" className="header__btn btn">
                            Оставить заявку
                        </HashLink>}
                    <button className="header__btn__mobile mobile-outline-btn btn" onClick={goToProfile}>
                        <ProfileSVG />
                    </button>
                    <HashLink to="/#request" className="header__btn__mobile btn">
                        <PlusSVG/>
                    </HashLink>
                </div>
            </div>
            <div className="fixed-menu">
                <div className="fixed-menu__icon fixed-menu__icon-yellow">
                    <HashLink to="/#request">
                        <PlusSVG/>
                    </HashLink>
                </div>
                <div className="fixed-menu__icon">
                    <ul className="popup-menu">
                        <li className="popup_menu_item">
                            <HashLink to="/#plans">
                                Тариф
                            </HashLink>
                        </li>
                        <li className="popup_menu_item">
                            <HashLink to="/#request">
                                Создание заявки
                            </HashLink>
                        </li>
                        <li className="popup_menu_item">
                            <HashLink to="/consumer">
                                Информация для потребителя
                            </HashLink>
                        </li>
                    </ul>
                    <BurgerSVG/>
                </div>
            </div>
            <AuthModal showModal={showModal} setShowModal={setShowModal}/>
        </header>
    )
}

export default Header
