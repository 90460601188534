import React, {useState, useEffect} from 'react';
import {getOrder} from "../../services/requests";
import {Link, useParams} from "react-router-dom";
import OrderStatus from "./components/OrderStatus";
import TsInfo from "./components/TsInfo";
import Spinner from "../../components/Spinner";
import NotFound from "../NotFound";
import {generateDateTime} from "../../lib";
import Modal from "../../components/Modal";
import WastePassportArea from './components/WastePassportArea';
import { PaperClip } from '../../components/svgImage';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Order = () => {
    const [order, setOrder] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [notFound, setNotFound] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const {id} = useParams()
    const userToken = useSelector(state => state.user.token);
    const userType = useSelector(state => state.user.user);
    const isUserLogged = userToken && userType !== 'FL';

    const loadOrder = () => {
      getOrder(id)
        .then((data) => {
            console.log(data)
            setOrder(data)
        })
        .catch(err => setNotFound(true))
        .finally(() => setIsLoading(false))
    }

    useEffect(() => {
      loadOrder();        
    }, [id])

    if (isLoading) {
        return <Spinner/>
    }
    if (notFound) {
        return <NotFound/>
    }
    const wastePassport = order.waste_passport_url

    return (
        <section className="order">
            <div className="container about__container">
                <div className='order__wrapper'>
                  <div className='order__column_left'>
                  <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb__item"><Link to="/">Главная</Link></li>
                        <li className="breadcrumb__item">Статус заявки</li>
                    </ol>
                  </nav>
                  <h2 className="section-title">Статус заявки</h2>
                  <div className="about__contacts contacts order_table">
                      <div className="contacts__item">
                          <div className="contacts__row">
                              <h5 className="contacts__title">ID заявки</h5>
                              <p className="order__id">{order.id}</p>
                          </div>
                          <div className="contacts__row">
                              <h5 className="contacts__title">Текущий статус</h5>
                              <OrderStatus status={order.status}/>
                          </div>
                      </div>
                      <div className="contacts__item">
                          <div className="contacts__row">
                              <h5 className="contacts__title">Дата и время создания заявки</h5>
                              <p className="contacts__info">{moment(order.create_date).format('DD/MM/YYYY HH:mm')}</p>
                          </div>
                      </div>
                      <div className="contacts__item">
                          <div className="contacts__row">
                              <h5 className="contacts__title">Интервал вывоза</h5>
                              <p className="contacts__info">{moment(order.waste_removal_date).format('DD/MM/YYYY')} {order.waste_removal_day_period}</p>
                          </div>
                      </div>
                      <div className="contacts__item">
                        <div className="contacts__row">
                          <h5 className="contacts__title">Информация о ТС</h5>
                          <p className="contacts__info">
                            <span className="ts-wrapper">
                                {order.vehicles ?
                                    order.vehicles.map(vehicle => (
                                        <TsInfo vehicle={vehicle} />
                                    ))
                                    : "-"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="contacts__item">
                          <div className="contacts__row">
                              <h5 className="contacts__title">Стоимость услуги</h5>
                              <p className="contacts__info">{order.price ? `${order.price / 100}р.` : "-"}</p>
                          </div>
                      </div>
                      {
                        (wastePassport && isUserLogged) && 
                          (<div className="contacts__item">
                            <div className="contacts__row">
                              <div className='waste-passport__attached-file'> 
                                <PaperClip className='waste-passport__attached-file__icon'/>
                                <a className="waste-passport__text" href={wastePassport}>Загруженный паспорт отходовв</a>
                              </div>
                            </div>
                          </div>)
                      }
                      {
                          order.status === 'CONFIRMED' &&
                          <button className="btn btn__pay" onClick={() => setShowModal(true)}>Оплатить</button>
                      }
                      <Modal isOpen={showModal} className='payment-modal'>
                          <button className="popup__close" onClick={() => {
                              setShowModal(false)
                          }}>
                              ×
                          </button>
                          <h2 className="auth__title">Оплатить</h2>
                          <iframe src={order.payment_link} className="payment-frame">
                              Ваш браузер не поддерживает плавающие фреймы!
                          </iframe>
                      </Modal>
                    </div>                    
                  </div>
                  <div className='order__column_right'>
                    {
                      isUserLogged && (
                        <WastePassportArea 
                          order_id={order.id} 
                          wastePassport={wastePassport} 
                          updateOrder={loadOrder}
                        />
                      )
                    }
                  </div>
                </div>
            </div>
        </section>
    )
};

export default Order;
