export const LOGIN_USER = "LOGIN_USER"
export const CODE_CONFIRMATION = "CODE_CONFIRMATION"
export const GET_USER = "GET_USER"
export const LOGOUT = "LOGOUT"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const GET_FL_STATIC_FILES = "GET_FL_STATIC_FILES"
export const GET_UL_STATIC_FILES = "GET_UL_STATIC_FILES"
export const GET_WASTE_PASSPORT_SAMPLE_FILE = "GET_WASTE_PASSPORT_SAMPLE_FILE"

export const AUTH_TOKEN = "AUTH_TOKEN"

export const CLIENT_TYPE = {
    FL: "CLIENT_FL",
    UL: "CLIENT_IP_UL",
}

export const WASTE_PASSPORT_SAMPLE_ID = 9