import React, {useEffect} from 'react';
import Cards from "../../components/Cards";
import {Link} from "react-router-dom";

const Consumer = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },)
    return (
        <section className="about">
            <div className="container about__container">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb__item"><Link to="/">Главная</Link></li>
                        <li className="breadcrumb__item active" aria-current="page">О компании</li>
                    </ol>
                </nav>
                <h2 className="section-title">Информация для потребителя</h2>
                <h3 className="section-subtitle">О компании</h3>
                <div className="about__contacts contacts">
                    <div className="contacts__item">
                        <div className="contacts__row">
                            <h5 className="contacts__title">ИНН</h5>
                            <p className="contacts__info">5010030980</p>
                        </div>
                        <div className="contacts__row">
                            <h5 className="contacts__title">КПП</h5>
                            <p className="contacts__info">501001001</p>
                        </div>
                        <div className="contacts__row">
                            <h5 className="contacts__title">ОГРН</h5>
                            <p className="contacts__info">1045002204455</p>
                        </div>
                    </div>
                    <div className="contacts__item">
                        <div className="contacts__row">
                            <h5 className="contacts__title">Юридический адрес</h5>
                            <p className="contacts__info">141980, Московская область, г. Дубна, проезд Автолюбителей, д. 14, стр. 4</p>
                        </div>
                    </div>
                    <div className="contacts__item">
                        <div className="contacts__row">
                            <h5 className="contacts__title">Телефон/факс</h5>
                            <p className="contacts__info">8 (496) 216-65-10</p>
                        </div>
                    </div>
                    <div className="contacts__item">
                        <div className="contacts__row">
                            <h5 className="contacts__title">Счет номер</h5>
                            <p className="contacts__info">4070 2810501 4600 00747</p>
                        </div>
                        <div className="contacts__row">
                            <h5 className="contacts__title">Банк</h5>
                            <p className="contacts__info">ПАО “Сбербанк”, г.Москва БИК 044525411</p>
                        </div>
                    </div>
                    <a href="#" className="contacts__download">Скачать реквизиты</a>
                </div>
                <h3 className="section-subtitle">Описание услуг</h3>
                <p className="about__content">Наша компания предоставляет услугу по вывозу отходов на территории Московской области контейнером типа бункер объемом 8 м³, 20м³, 27м³, 36м³.<br />Бункеры-накопители наиболее востребованы в сфере вывоза отходов от строительных и ремонтных работ, т.к. просты в эксплуатации, легко транспортируются даже в компактные дворы с высокой запаркованностью, а также дают возможность беспрепятственной загрузки отходов.</p>
                <p className="about__content">Транспортирование осуществляется специализированной техникой -
                    бункеровозами КАМАЗ,
                    оснащенными системой мониторинга ГЛОНАСС.</p>
                <Cards />
                <h3 className="section-subtitle">Процедура возврата</h3>
                    <p className="about__content">В случае предъявления Отходопроизводителем требования о возврате суммы произведенной им оплаты услуг по удалению отходов от строительных и ремонтных работ, ООО РФК Экосистема возвращает сумму полученной оплаты услуги.

      Срок возврата денежных средств: в течение 10 календарных дней со дня предъявления Отходопроизводителем требования о возврате суммы произведенной им оплаты услуг.

      Условия для возврата денежных средств:
      - услуги, в отношении которых Отходопроизводителем внесена оплата на банковский счет ООО РФК Экосистема, не оказаны потребителю в определенный договором срок;
      - Отходопроизводителем направил ООО РФК Экосистема уведомление об отказе от исполнения договора оказания услуг в связи с нарушением ООО РФК Экосистемой сроков оказания услуг, определенных договором.
                    </p>
            </div>
        </section>
    );
};

export default Consumer;
