import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {userAuth} from "../../redux/actions/userActions";
import InputMask from "react-input-mask";
import {brPhoneMaskProperties} from "../../lib";

const PhoneForm = ({setStep}) => {
    const [phone, setPhone] = useState("")
    const dispatch = useDispatch()
    const handleSubmit = (e) => {
        e.preventDefault()
        setStep(1)
        dispatch(userAuth(phone.replace(/[\D]+/g, '')))
    }
    const handleChange = (e) => setPhone(e.target.value)
    return (
        <form onSubmit={handleSubmit}>
            <p className="auth__info">Введите номер телефона, на него будет отправлен 4-х значный код подтверждения</p>
            <InputMask
                name="phone"
                type="text"
                className="form__input auth__input"
                placeholder="Номер телефона"
                mask={brPhoneMaskProperties.mask}
                onChange={handleChange}
                required
              />
            <button type="submit" className="btn auth__btn" disabled={phone.replace(/[\D]+/g, '').length !== 11}>
                Далее
            </button>
        </form>
    );
};

export default PhoneForm;