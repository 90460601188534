import React from 'react'
import * as RModal from 'react-modal'

const Modal = ({ isOpen, children, className }) => {
  RModal.setAppElement('#root')
  return (
    <RModal isOpen={isOpen} className="popup">
      <div className={`popup__wrap ${className}`}>{children}</div>
    </RModal>
  )
}

export default Modal
