import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {confirmCode} from "../../redux/actions/userActions";
import {useHistory} from "react-router-dom";
import InputMask from "react-input-mask";

const ConfirmCode = ({setShowModal}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {success, error} = useSelector(s => s.user)
    const [code, setCode] = useState("")
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(confirmCode(code.replace(/\s/g, "")))
    }

    const handleChange = (e) => setCode(e.target.value)

    useEffect(() => {
        success && history.push("/profile")
        success && setShowModal(false)
    }, [history, setShowModal, success])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <p className="auth__info">Введите код из СМС</p>
                <InputMask
                    name="phone"
                    type="text"
                    className="form__input auth__input confirm-code"
                    placeholder="Введите код из смс"
                    mask="9 9 9 9"
                    maskPlaceholder="— — — —"
                    onChange={handleChange}
                    required
                />
                {<div>{error && <span className="error-text">Неверный код</span>}</div>}
                <button type="submit" className="btn auth__btn" disabled={code.replace(/[\D]+/g, '').length !== 4}>
                    Подтвердить
                </button>
            </form>
        </>
    );
};

export default ConfirmCode;