import axiosLib, { Method } from 'axios'
import { AUTH_TOKEN } from '../redux/constants'

class RequestNotFoundError extends Error {
  constructor(requestKey) {
    super(`Роут не найден. Используемый ключ: ${requestKey}`)
  }
}

export class APIService {
  /** Текущая стабильная версия */
  static CURRENT_VERSION = '1'

  /** Существующие запросы */
  static REQUESTS = {
    CUSTOMER_REGISTER: ['POST', 'customer/register'],
    CUSTOMER_GET: ['GET', 'customer'],
    CUSTOMER_VERIFY: ['POST', 'customer/verify'],
    ORDER_CREATE_FROM_PORTAL_FL: ['POST', 'order/create_from_portal_fl'],
    ORDER_CREATE_FROM_PORTAL_UL: ['POST', 'order/create_from_portal_ul'],
    ORDER_CREATE_FROM_PORTAL_IP: ['POST', 'order/create_from_portal_ip'],
    ORDER_GET: ['GET', 'order/{{ID}}'],
    STATIC_DOCUMENT_GET: ['GET', 'static-document'],
    FILE_UPLOAD: ['POST', 'file/upload'],
    ORDER_ATTACH_WASTE_PASSPORT: ['PUT', 'order/attach-waste-passport'],
    LOAD_ACTIVE_POLYGONS: ['GET', 'municipal-district'],
    LOAD_ACTIVE_MULTIPOLYGONS: ['GET', 'municipal-district/multipolygones'],
    LOAD_VEHICLE_TYPE: ['GET', 'dictionary/vehicle_type']
  }

  /** Инстанс axios */
  _axios = this._initAxios(axiosLib.create())

  /**
   * Создание Promise запроса
   * @param {[Method, string]} requestKey ключ {@link  APIService.REQUESTS}
   * @param {any} body данные
   * @param {object} requestOptions Доп. опции, добавленыые в axios (headers, params, etc.) прим. { params: {limit: 10, offset: 0}}
   * @param {object} routeReplaceFlags Доп. опции, добавленыые в axios (headers, params, etc.)
   * @param {string | number} customVersion Версия, которую необходимо использовать, отличающаяся от stable( {@link APIService.CURRENT_VERSION} )
   * @returns {Promise} axios запрос
   */
  createRequest(request, options = {}) {
    const {
      body = {},
      requestOptions = {},
      routeReplaceFlags = {},
      version = APIService.CURRENT_VERSION
    } = options
    const [method, route] = request
    const formattedRoute = this._replaceRouteFlags(route, routeReplaceFlags)
    return this._axios({
      method,
      url: `/v${version.toString()}/${formattedRoute}`,
      data: body,
      ...(requestOptions ? requestOptions : {})
    })
  }

  /**
   * Инициализация axios
   * @param { import('axios').AxiosInstance } axios
   */
  _initAxios(axios) {
    axios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem(AUTH_TOKEN)
        return {
          ...config,
          headers: {
            ...config.headers,
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
          }
        }
      },
      (error) => Promise.reject(error)
    )
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          localStorage.removeItem(AUTH_TOKEN)
        }
        return Promise.reject(error)
      }
    )
    return axios
  }

  /**
   * Заменяет флаги на данные
   * @param {string} route Роут
   * @param {object} flags Флаги, необходимые для замены
   * @returns {string} Исправленный роут
   */
  _replaceRouteFlags(route, flags) {
    return Object.entries(flags).reduce(
      (curr, [key, value]) => curr.replace(key, value),
      route.toString()
    )
  }
}

const api = new APIService()
export const createRequest = api.createRequest.bind(api)
export default APIService
