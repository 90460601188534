import React from 'react';
import {Route, Redirect} from "react-router-dom";
import {useSelector} from "react-redux";

const PrivatRoute = ({children, ...rest}) => {
    const {user, token} = useSelector(store => store.user)
    return (
        <Route
            {...rest}
            render={(props) => {
                if (token) {
                    return children
                } else {
                    return (
                        <Redirect to={{pathname: "/", state: {from: props.location}}} />
                    )
                }
            }}
        />
    );
};

export default PrivatRoute;