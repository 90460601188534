export const statuses = {
  NEW: 'Новая',
  CONFIRMITATION: 'На рассмотрении',
  CONFIRMED: 'Подтверждена',
  IN_PROCESS: 'На исполнении',
  IN_COMBO: 'В процессе',
  DRIVER_DEFINED: 'Водитель определён',
  DRIVER_LEFT: 'Водитель выехал',
  DRIVER_ARRIVED: 'Водитель прибыл',
  LOADING_STARTED: 'Погрузка начата',
  LOADING_COMPLETED: 'Погрузка завершена',
  COMPLETED: 'Исполнена',
  PARTIALLY_COMPLETED: 'Частично исполнена',
  CANT_BE_COMPLETED: 'Не может быть исполнена',
  REJECTED: 'Отклонена',
  CASH_RETURN: 'Возврат денег',
  REMOVAL_COMPLETED: 'Вывоз завершен'
}
