import React, {useState} from 'react'
import {AndroidSVG, AppStore, InfoSVG} from '../svgImage'
import {Link} from "react-router-dom";
import Modal from "../Modal";
import {YMInitializer} from "react-yandex-metrika";
import {APP_STORE, PLAY_MARKET} from "../../lib";

const Footer = () => {
    const [privacyModal, setPrivacyModal] = useState(false)
    return (
        <footer className="footer">
            <div className="container footer__container">
                <div className="footer__row">
                    <div className="footer__col contacts">
                        <h3 className="footer__company">
                            Общество с ограниченной ответственностью "РФК ЭКОСИСТЕМА"
                        </h3>
                    </div>
                    <div className="footer__col contacts">
                        <div className="contacts__item">
                            <div className="contacts__row">
                                <h5 className="contacts__title">ИНН</h5>
                                <p className="contacts__info">5010030980</p>
                            </div>
                            <div className="contacts__row">
                                <h5 className="contacts__title">КПП</h5>
                                <p className="contacts__info">501001001</p>
                            </div>
                            <div className="contacts__row">
                                <h5 className="contacts__title">ОГРН</h5>
                                <p className="contacts__info">1045002204455</p>
                            </div>
                        </div>
                        <div className="contacts__item">
                            <div className="contacts__row">
                                <h5 className="contacts__title">Юридический/Фактический адрес</h5>
                                <p className="contacts__info">
                                    141980, Московская область, г.Дубна, проезд Автолюбителей, д.14, стр.4
                                </p>
                            </div>
                        </div>
                        <div className="contacts__item">
                            <div className="contacts__row">
                                <h5 className="contacts__title">Телефон/факс</h5>
                                <p className="contacts__info">8 496 216 65 10</p>
                            </div>
                        </div>
                    </div>
                    <div className="footer__col contacts">
                        <Link to="/consumer" className="contacts__row footer__link">
                            <InfoSVG/>
                            <span>Информация для отходопроизводителя</span>
                        </Link>
                        <span>
              По всем вопросам вы можете написать нам на почту <a href="mailto:aso@regop.ru">aso@regop.ru</a>
              </span>
                        <div className="footer__application">
                            <a href={PLAY_MARKET} target="blank">
                                <AndroidSVG/>
                            </a>
                            <a href={APP_STORE} target="blank">
                                <AppStore/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <div className="container copyright__container">
                    <h6 className="copyright__label">
                        Общество с ограниченной ответственностью "РФК ЭКОСИСТЕМА", 2021
                    </h6>
                    <button className="copyright__privacy popup-content" data-effect="mfp-zoom-in"
                            onClick={() => setPrivacyModal(true)}
                    >
                        Политика конфиденциальности
                    </button>
                </div>
            </div>
            <Modal isOpen={privacyModal} className="terms popup__large">
                <h2 className="popup-title">СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
                </h2>
                <p>
                    1. Я свободно, по собственной воле и в своих интересах ДАЮ конкретное, информированное и
                    сознательное СОГЛАСИЕ на обработку (включая сбор, запись, систематизацию, накопление, хранение,
                    уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ),
                    блокирование, удаление, уничтожение) следующих моих персональных данных: имя, фамилия, отчество,
                    телефон, e-mail,
                    предоставленных мною посредством формы заявки на вывоз отходов на сайте RFC-ECO.RU
                    в ЦЕЛЯХ: оказания и сопровождения услуги по вывозу отходов; осуществления информирования, обратной
                    связи по вопросам вывоза отходов
                    <br/>Оператору: ООО "РФК ЭКОСИСТЕМА"
                    находящемуся по адресу: Московская область, г.Дубна, проезд Автолюбителей, д.14, стр.4.
                    Оператор вправе осуществлять автоматизированную обработку персональных данных, указанных в пункте 1
                    настоящего Согласия, или их обработку без использования средств автоматизации, в том числе с
                    передачей по каналам связи.
                </p>
                <p>
                    2. Для достижения целей, указанных в пункте 1 настоящего Согласия, СОГЛАШАЮСЬ с тем, что Оператор
                    вправе поручить обработку моих персональных данных, указанных в пункте 1 настоящего Согласия,
                    другому лицу:
                    обществу с ограниченной ответственностью «Управляющая компания «Региональный оператор», находящемуся
                    по адресу: 143421, Московская область, Красногорский район, Автодорога Балтия, территория 26 км
                    Бизнес-Центр Рига-Ленд, строение Б2, строение 5, подъезд 2, этаж 2, помещение 13, комната 13
                    на основании заключенного с этим лицом (лицами) договора с указанием конкретной цели обработки, при
                    этом ответственность передо мной (субъектом персональных данных) за действия указанного лица несет
                    Оператор. Лицо, осуществляющее обработку персональных данных по поручению Оператора, несет
                    ответственность перед Оператором.
                </p>
                <p>
                    3. Настоящее Согласие действует с момента формирования и направления Оператору моего обращения НА
                    СРОК ОБРАБОТКИ – 3 ГОДА, либо до момента его отзыва путем личного обращения с заявлением об отзыве
                    Согласия.
                </p>
                <p>
                    4. Подтверждаю, что проинформирован(-а) о ВОЗМОЖНОСТИ ОТЗЫВА настоящего Согласия.
                </p>
                <p>
                    5. Подтверждаю, что ознакомлен с Политикой Оператора в отношении обработки ПДн (ссылка).
                </p>
                <button className="popup__close" onClick={() => setPrivacyModal(false)}>
                    ×
                </button>
            </Modal>
            <YMInitializer accounts={[85685322]} options={{webvisor: true, defer: true}}/>
        </footer>
    )
}

export default Footer
